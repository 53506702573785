$(function(){
  // navbar
  // $(".navbar-toggler").on('click',function(){
  //   $(".menu1").toggleClass("menuclick1");
  //   $(".menu2").toggleClass("menuclick2");
  //   $(".menu3").toggleClass("menuclick3");
  // });

  // スマホメニュー外部クリックで非表示
  $('.navbar-nav').click(function(){
    $('#navbars').removeClass('show');
  });

  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $(".to-page-top").fadeOut();
    } else {
      $(".to-page-top").fadeIn();
      $(".to-page-top a").smoothScroll({offset: -80});
    }
  }
  $(window).on("load scroll", displayToTop);

  //navの黒背景表示・非表示
  function displayNavBg(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 10) {
      $(".navbar-bg").slideUp();
    } else {
      $(".navbar-bg").slideDown();
    }
  }
  $(window).on("load scroll", displayNavBg);


  // ローカルナビゲーション
  $("#localnav a").smoothScroll({
    offset: -60
  });

  // スマホ以外電話リンクOFF
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
  if (!isMobile) {
      $('a[href^="tel:"]').on('click', function(e) {
          e.preventDefault();
      }).addClass('disable');
  }

  $('#menu .menu, a.btn, #navbar a').on('touchstart', function(){
    $(this).addClass('hover');
  }).on('touchend', function(){
    $(this).removeClass('hover');
  });

  // copyright年
  var copyrightY = (new Date).getFullYear();
  $(".copyright span").text(copyrightY);

  //home
  if ($('body').hasClass('home')) {
    $('#menu .menu').click(function(){
      window.location = $(this).find('a').attr('href');
     });
  }

  //about
  if ($('body').hasClass('about')) {
    // read moreボタン
    var flg = "close";
    $('.outline-viewmore-btn p').on("click", function() {
      $(this).parent().prev().toggleClass('slide-open').slideToggle();
      if(flg == "close"){
        flg = "open";
        // $(this).text("close");
        $(this).text("").wrapInner('<a href="#outline-viewmore">close</a>');
      }else{
        flg = "close";
        $(this).empty().text("read more");
      }
      $('.outline-viewmore-btn p a').smoothScroll({offset: -80});
    });
    $('p.btn').on('touchstart', function(){
      $(this).addClass('hover').removeClass('nothover');
    }).on('touchend', function(){
      $(this).removeClass('hover').addClass('nothover');
    });
  }
});
